import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />

        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>

          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    `Graduating from UC San Diego's coding bootcamp, I soon found myself working as an educator in Web Development as well as a full-time Full-Stack Web Developer at one of the fastest growing e-commerce sites in the US, MANSCAPED. `}
                </p>

                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    `I love building new things, exploring new frameworks, automating monotonous tasks, and mastering the art of code. I know that the life of a developer is a constantly changing one, and I am enamoured by the exciting challenges and growth in this space.`}
                </p>

                <p className="about-wrapper__info-text">
                  {paragraphThree ||
                    `I'm as much of a hobbyist programmer as I am a professional one, and I know how to leverage my soft skills to be a great addition to any work environment. Please feel free to reach out if you see me as a potential candidate.`}
                </p>

                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
