import React, { useContext } from 'react';
// import { Transition, animated } from 'react-spring/renderprops';
// import Fade from 'react-reveal/Fade';

import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import Project from '../Project/Project';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);
  // const [isListOpen, setIsListOpen] = useState(false);

  // const manscapedProjects = projects.filter((project) => project.img.includes('manscaped'));
  // const featuredManscapedProjects = manscapedProjects.splice(0, 2);

  const personalProjects = projects.filter((project) => !project.img.includes('manscaped'));

  // const handleToggleList = (isOpen) => {
  // setIsListOpen(isOpen);
  // };

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          {/* <div className="manscaped-projects">
            <Fade bottom duration={1000} delay={300} distance="0px">
              <h2 className="section-title">
                Notable
                <br />
                Manscaped Projects
              </h2>
            </Fade>

            {featuredManscapedProjects.map(({ title, info, techStack, url, repo, img, id }) => {
              return (
                <Project
                  id={id}
                  title={title}
                  info={info}
                  techStack={techStack}
                  url={url}
                  repo={repo}
                  img={img}
                />
              );
            })}

            <Button
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--seemore"
              onClick={() => handleToggleList(!isListOpen)}
            >
              {isListOpen ? 'Show Less' : 'Show More'}
            </Button>

            <Transition
              items={isListOpen}
              from={{ opacity: 0, height: 0 }}
              enter={{ opacity: 1, height: 'auto' }}
              leave={{ opacity: 0, height: 0 }}
              config={{ mass: 5, tension: 100, friction: 25 }}
            >
              {(show) =>
                show &&
                ((props) => (
                  <animated.div style={props}>
                    <div className="hidden-projects">
                      {manscapedProjects.map(({ title, info, techStack, url, repo, img, id }) => {
                        return (
                          <Project
                            id={id}
                            title={title}
                            info={info}
                            techStack={techStack}
                            url={url}
                            repo={repo}
                            img={img}
                          />
                        );
                      })}
                    </div>
                  </animated.div>
                ))
              }
            </Transition>
          </div> */}

          <Title title="Personal Projects" />
          {personalProjects.map(({ title, info, techStack, url, repo, img, id }) => {
            return (
              <Project
                id={id}
                title={title}
                info={info}
                techStack={techStack}
                url={url}
                repo={repo}
                img={img}
              />
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
